<template>
  <div>
    <simple-list-property
      v-for="draft in drafts"
      :key="draft.id"
      :property="draft"
    >
      <template #actions>
        <p v-if="!_.get(draft, 'propertyPackage', false)" class="d-none"></p>
        <img
          v-if="_.get(draft, 'propertyPackage', false)"
          @click="decideBetweenPackageOrPayment(draft)"
          class="w-30 margin-r-5 money-pound"
          :src="
            require('../assets/images/icons/Vencasa-Payment-Icon-Green.webp')
          "
        />
        <img
          v-if="!_.get(draft, 'propertyPackage', false)"
          @click="complete(draft.id)"
          class="w-30 edit-box"
          :src="require('../assets/images/icons/Vencasa-edit-icon-Green.webp')"
        />
        <img
          v-if="_.get(draft, 'propertyPackage', false)"
          @click="$router.push(`/edit-property/${draft.id}`)"
          class="w-30 edit-box"
          :src="require('../assets/images/icons/Vencasa-edit-icon-Green.webp')"
        />
        <b-modal
          @shown="setHeight(draft.id)"
          centered
          :ref="'deleteDraft' + draft.id"
          :id="'deleteDraft' + draft.id"
          title="Alert"
        >
          <p>Are you sure you want to permanently delete this listing?</p>
          <template #modal-footer>
            <b-button
              @click="$refs['deleteDraft' + draft.id][0].hide()"
              variant="secondary"
              size="md"
              class="listing-button-color-secondary btn btn-primary"
            >
              Cancel
            </b-button>
            <b-button
              @click="deleteDraft(draft.id)"
              variant="primary"
              size="md"
              class="listing-button-color btn btn-primary res-button-how"
            >
              Delete
            </b-button>
          </template>
        </b-modal>
        <b-modal
          @shown="setHeightListing(draft.id)"
          centered
          :ref="'deleteListing' + draft.id"
          :id="'deleteListing' + draft.id"
          title="Alert"
        >
          <p>Are you sure you want to permanently delete this listing?</p>
          <template #modal-footer>
            <b-button
              @click="$refs['deleteListing' + draft.id][0].hide()"
              variant="secondary"
              size="md"
              class="listing-button-color-secondary btn btn-primary"
            >
              Cancel
            </b-button>
            <b-button
              @click="deleteList(draft.id)"
              variant="primary"
              size="md"
              class="listing-button-color btn btn-primary res-button-how"
            >
              Delete
            </b-button>
          </template>
        </b-modal>
        <img
          @click="openModal(draft.id)"
          v-if="_.get(draft, 'propertyPackage', false)"
          class="w-30 chat-delete"
          :src="
            require('../assets/images/icons/Vencasa-Chat-Delete-Green.webp')
          "
        />
        <img
          v-if="!_.get(draft, 'propertyPackage', false)"
          @click="$refs[`deleteDraft${draft.id}`][0].show()"
          class="w-30 chat-delete"
          :src="
            require('../assets/images/icons/Vencasa-Chat-Delete-Green.webp')
          "
        />
        <br
          v-if="_.get(draft, 'propertyPackage', false)"
          class="res-hide-big"
        />
      </template>
      <template #createdAt>
        <p class="d-none"></p>
      </template>
      <template #descriptionTitle="{ property }">
        <span
          style="text-align: justify; margin-top: 2px !important"
          class="mt-n4"
        >
          {{
            property.description
              ? property.description[0]
                ? `${
                    property.description[0].value[
                      Object.keys(property.description[0].value)[0]
                    ]
                  }`
                : ""
              : ""
          }}</span
        >
      </template>
      <template v-if="screenWidth >= 760" #buttons>
        <button
          v-if="!_.get(draft, 'propertyPackage', false)"
          class="res-button-how"
          @click="complete(draft.id)"
          style="
            width: 25%;
            padding: 10px 0px;
            border: none;
            background-color: #618c57;
            color: #fff;
            border-radius: 5px;
            font-size: 15px;
          "
        >
          Complete
        </button>
        <button
          v-if="!_.get(draft, 'propertyPackage', false)"
          class="res-button-how"
          @click="$refs[`deleteDraft${draft.id}`][0].show()"
          style="
            width: 25%;
            padding: 10px 0px;
            border: none;
            background-color: #618c57;
            color: #fff;
            border-radius: 5px;
            font-size: 15px;
          "
        >
          Delete
        </button>
        <button
          v-if="_.get(draft, 'propertyPackage', false)"
          style="
            width: 25%;
            padding: 10px 0px;
            border: none;
            background-color: #618c57;
            color: #fff;
            border-radius: 5px;
            font-size: 15px;
          "
          class="notification res-button-how"
        >
          Offers
        </button>
        <button
          v-if="_.get(draft, 'propertyPackage', false)"
          style="
            width: 25%;
            padding: 10px 0px;
            border: none;
            background-color: #618c57;
            color: #fff;
            border-radius: 5px;
            font-size: 15px;
          "
          class="notification res-button-how"
        >
          Messages
        </button>
      </template>
      <template v-if="screenWidth <= 760" #buttonsAlt>
        <button
          v-if="!_.get(draft, 'propertyPackage', false)"
          class="res-button-how"
          @click="complete(draft.id)"
          style="
            width: 100%;
            margin-top: 5px;
            margin-bottom: 15px;
            padding: 10px 0px;
            border: none;
            background-color: #618c57;
            color: #fff;
            border-radius: 5px;
            font-size: 15px;
          "
        >
          Complete
        </button>
        <button
          v-if="!_.get(draft, 'propertyPackage', false)"
          class="res-button-how"
          @click="$refs[`deleteDraft${draft.id}`][0].show()"
          style="
            width: 100%;
            margin-top: 5px;
            margin-bottom: 15px;
            padding: 10px 0px;
            border: none;
            background-color: #618c57;
            color: #fff;
            border-radius: 5px;
            font-size: 15px;
          "
        >
          Delete
        </button>
        <button
          v-if="_.get(draft, 'propertyPackage', false)"
          style="
            width: 100%;
            margin-top: 5px;
            margin-bottom: 15px;
            padding: 10px 0px;
            border: none;
            background-color: #618c57;
            color: #fff;
            border-radius: 5px;
            font-size: 15px;
          "
          class="notification res-button-how"
        >
          Offers
        </button>
        <button
          v-if="_.get(draft, 'propertyPackage', false)"
          style="
            width: 100%;
            margin-top: 5px;
            margin-bottom: 15px;
            padding: 10px 0px;
            border: none;
            background-color: #618c57;
            color: #fff;
            border-radius: 5px;
            font-size: 15px;
          "
          class="notification res-button-how"
        >
          Messages
        </button>
      </template>
    </simple-list-property>
  </div>
</template>

<script>
const SimpleListProperty = () =>
  import(
    /* webpackChunkName: "NWKGKyYLa5" */ "../components/simple-list-property"
  );
import { mapGetters } from "vuex";
import { formatter } from "../utils";
import _ from "lodash";

export default {
  props: ["unPaidProperties"],
  data() {
    return {
      screenWidth: window.innerWidth,
      drafts: [],
    };
  },
  components: {
    SimpleListProperty,
  },
  computed: {
    _() {
      return _;
    },
    ...mapGetters({
      propertyDrafts: "getPropertyDraft",
    }),
  },
  methods: {
    encrypt(key) {
      return this.$store.dispatch("encrypt", { key: key }).then((res) => {
        if (res.status == 200) return _.get(res, "data.data.encrypted", false);
        else return false;
      });
    },
    setHeightListing(propertyId) {
      document
        .querySelector(`#deleteListing${propertyId}___BV_modal_content_`)
        .setAttribute(
          "style",
          "height : auto !important; display: block !important"
        );
      document
        .querySelector("#deleteListing" + propertyId)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteListing${propertyId}___BV_modal_body_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteListing${propertyId}___BV_modal_backdrop_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteListing${propertyId}___BV_modal_outer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteListing${propertyId}___BV_modal_header_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteListing${propertyId}___BV_modal_footer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteListing${propertyId}___BV_modal_title_`)
        .setAttribute("style", "display: block !important");
    },
    setHeight(propertyId) {
      document
        .querySelector(`#deleteDraft${propertyId}___BV_modal_content_`)
        .setAttribute(
          "style",
          "height : auto !important; display: block !important"
        );
      document
        .querySelector("#deleteDraft" + propertyId)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteDraft${propertyId}___BV_modal_body_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteDraft${propertyId}___BV_modal_backdrop_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteDraft${propertyId}___BV_modal_outer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteDraft${propertyId}___BV_modal_header_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteDraft${propertyId}___BV_modal_footer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteDraft${propertyId}___BV_modal_title_`)
        .setAttribute("style", "display: block !important");
    },
    async deleteList(propertyId) {
      this.$store.commit("setShowLoader", true);
      await this.$store
        .dispatch("deleteProperty", propertyId)
        .then(async (res) => {
          this.$refs[`deleteListing${propertyId}`][0].hide();
          this.$store.commit("setShowLoader", false);
          if (res == true) {
            await this.$store.dispatch("getUserProperties").then((res) => {
              if (res.status == 400)
                this.$store.commit("setUserProperties", []);
            });
            this.$store.dispatch("getPropertyDraft").then((res) => {
              if (res == true) {
                this.drafts = [];
                this.propertyDrafts?.forEach((draft, index) => {
                  this.drafts = [...this.drafts, JSON.parse(draft.property)];
                  this.drafts[index].id = draft.id;
                  this.drafts[index].createdAt = draft.createdAt;
                });
                this.drafts = _.orderBy(
                  [...this.drafts, ...this.unPaidProperties],
                  ["createdAt"],
                  ["desc"]
                );
              }
            });
          }
        });
    },
    openModal(id) {
      this.$nextTick(() => {
        this.$refs[`deleteListing${id}`][0].show();
      }, 1000);
    },
    async decideBetweenPackageOrPayment(property) {
      if (_.get(property.propertyPackage, "[0]", false)) {
        if (_.get(property.propertyPackage, "[0].status", false) == "pending") {
          let cipherText = await this.encrypt(property.id);
          this.$router.push(
            `/add-property/?propertyId=${
              !cipherText ? property.id : cipherText
            }&pending=payment`
          );
        }
      } else {
        let cipherText = await this.encrypt(property.id);
        this.$router.push(
          `/add-property/?propertyId=${
            !cipherText ? property.id : cipherText
          }&pending=package`
        );
      }
    },
    relist(id) {
      this.$store.dispatch("relist", id).then(async (res) => {
        if (res == true) {
          this.$refs[`relist${id}`].hide();
          this.$store.commit("setMessage", "Property relisted");
          await this.$store.dispatch("getUserProperties");
          this.$refs.table.refresh();
        } else {
          this.$refs[`relist${id}`].hide();
          this.$store.commit("setMessage", "Property not relisted");
        }
      });
    },
    sliceString(str) {
      if (str) {
        if (str.length > 280) {
          this.shouldShow = true;
          return str.slice(0, 280);
        } else return str;
      } else return "";
    },
    formatter: formatter,
    refresh() {
      if (!this.propertyDrafts)
        this.$store.dispatch("getPropertyDraft").then((res) => {
          if (res == true) {
            this.drafts = [];
            this.propertyDrafts?.forEach((draft, index) => {
              this.drafts = [...this.drafts, JSON.parse(draft.property)];
              this.drafts[index].id = draft.id;
              this.drafts[index].createdAt = draft.createdAt;
            });
            this.drafts = _.orderBy(
              [...this.drafts, ...this.unPaidProperties],
              ["createdAt"],
              ["desc"]
            );
          }
        });
      else {
        this.propertyDrafts?.forEach((draft, index) => {
          this.drafts = [...this.drafts, JSON.parse(draft.property)];
          this.drafts[index].id = draft.id;
          this.drafts[index].createdAt = draft.createdAt;
        });
        this.drafts = _.orderBy(
          [...this.drafts, ...this.unPaidProperties],
          ["createdAt"],
          ["desc"]
        );
      }
    },
    deleteDraft(id) {
      this.$store.dispatch("deletePropertyDraft", id).then((res) => {
        if (res == true) {
          this.$refs[`deleteDraft${id}`][0].hide();
          this.$store.dispatch("getPropertyDraft").then((res) => {
            if (res == true) {
              this.drafts = [];
              this.propertyDrafts?.forEach((draft, index) => {
                this.drafts = [...this.drafts, JSON.parse(draft.property)];
                this.drafts[index].id = draft.id;
                this.drafts[index].createdAt = draft.createdAt;
              });
              this.drafts = _.orderBy(
                [...this.drafts, ...this.unPaidProperties],
                ["createdAt"],
                ["desc"]
              );
            }
          });
        }
      });
    },
    complete(id) {
      let currentDraft = this.propertyDrafts?.find((draft) => draft.id == id);
      if (currentDraft) {
        this.$store.commit("setCurrentDraft", currentDraft);
        this.$router.push("/add-property");
      }
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style scoped>
.w-30 {
  width: 30px;
  height: 30px;
}
.margin-r-5 {
  margin-right: 5px;
}

.money-pound:hover {
  content: url("../assets/images/icons/Vencasa-Payment-Icon-Filled.webp");
}
.edit-box:hover {
  content: url("../assets/images/icons/Vencasa-edit-icon-fill.webp");
}
.chat-delete:hover {
  content: url("../assets/images/icons/Vencasa-chat-delet-fill.webp");
}
</style>